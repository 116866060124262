import React, { useState, useEffect } from "react";
import { Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import SearchResultStyles from "./SearchResultStyles";
import { routes } from "../../../../routes";
import StarIcon from "../../../StarIcon/StarIcon";
import { numberWithCommas } from "../../../../helper/helper";
import CardCustom from "../../../CardCustom/CardCustom";
import InitialAvatar from "../../../../components/InitialAvatar/InitialAvatar";
import { setSelectedCompany } from "../../../../redux/reducers/jobReducer";

import BackArrow from "../../../../assets/svg/f-btn-arrow-gray.svg";
import ArrowIcon from "../../../../assets/svg/down-arrow.svg";

const SearchResult = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { companies = [], selectedCompany } = useSelector(
    (store) => store.jobStore
  );

  const [resultId, setResultId] = useState(null);
  const [openFilter, toggleFilter] = useState(false);

  const { saveCompaniesFeature = false } = props;

  useEffect(() => {
    if (companies && companies.length > 0)
      props.openMap
        ? sendDataOnMap(
            selectedCompany !== null ? selectedCompany : companies[0]
          )
        : openCompanyDetail(
            selectedCompany !== null ? selectedCompany : companies[0]
          );
  }, [companies]);

  useEffect(() => {
    if (selectedCompany !== null) {
      setResultId(selectedCompany.company_id);
      toggleFilter(false);
    }
  }, [selectedCompany]);

  const sendDataOnMap = (data) => {
    dispatch(setSelectedCompany(data));
    setResultId(data.company_id);
  };

  const openCompanyDetail = (data) => {
    dispatch(setSelectedCompany(data));
    setResultId(data.company_id);
  };

  const favCompanyKeyList = saveCompaniesFeature
    ? props.savedCompaniesList && props.savedCompaniesList.length > 0
      ? props.savedCompaniesList.map(({ company_id }) => company_id)
      : []
    : [];

  const renderSearchResult = (data, i) => {
    return (
      <CardCustom
        className={`search-card cursor-pointer mb-15 ${
          resultId === data.company_id && "active"
        }`}
      >
        <div
          className="search-item"
          onClick={() =>
            props.back === "searchList"
              ? openCompanyDetail(data)
              : sendDataOnMap(data)
          }
        >
          <div className="avtar">
            {data.company_logo ? (
              <Avatar
                alt={
                  data.employer_name
                    ? `${data.employer_name}'s logo`
                    : "default logo"
                }
                src={data.company_logo}
                className="white-background"
              />
            ) : (
              <InitialAvatar
                variant="square"
                title={data.employer_name}
                index={i}
                initialCount={1}
              />
            )}
          </div>
          <div className="content">
            <div className="header-primary">
              {data.employer_name}
              {saveCompaniesFeature ? (
                <>
                  {favCompanyKeyList.includes(data.company_id) ? (
                    <StarIcon
                      aria-label={`Remove ${data.employer_name} from saved companies`}
                      variant="filled"
                      onClick={() =>
                        props.updateFavourites({
                          ...data,
                          like: "unlike",
                        })
                      }
                    />
                  ) : (
                    <StarIcon
                      aria-label={`Add ${data.employer_name} to saved companies`}
                      onClick={() =>
                        props.updateFavourites({
                          ...data,
                          like: "like",
                        })
                      }
                    />
                  )}
                </>
              ) : null}
            </div>
            <div className="sub-header-primary">
              {numberWithCommas(data.no_of_petition ?? 0)} petitions
            </div>
            <div className="sub-header-primary">
              Average salary:{" "}
              {data.wage_rate_of_pay_from
                ? `$${numberWithCommas(data.wage_rate_of_pay_from)}`
                : "NA"}
            </div>
          </div>
        </div>
      </CardCustom>
    );
  };
  return (
    <SearchResultStyles>
      <div className="back-btn-wrapper">
        <a
          className="shadow-btn"
          onClick={(e) => {
            e.preventDefault();
            props.chageViewMode && props.chageViewMode();
            props.back
              ? props.back(true)
              : props.back2 === "jobs"
              ? navigate(routes.JOBS.SEARCH)
              : navigate(routes.JOBS.VISA, {
                  state: { preserveFilters: true },
                });
          }}
          href="/#"
          aria-label="Back"
        >
          <img src={BackArrow} id="BackArrow" width="18px" alt="Back arrow" />
          <span>Back to Search</span>
        </a>
      </div>
      <div
        className={`search-list collapsible-view ${openFilter ? "open" : ""}`}
      >
        <div className="search-title">
          <div className="title-primary mb-15">
            Companies
            <a
              href="/#"
              onClick={(e) => {
                e.preventDefault();
                toggleFilter(!openFilter);
              }}
              className="collapse-icon arrow"
            >
              <img src={ArrowIcon} alt="Arrow Icon" />
            </a>
          </div>
        </div>
        <div className={props.fixedHeight ? "fixed-companies-wrapper" : ""}>
          {/* Show only selected company when redirected from jobs */}
          {props.back2 === "jobs" ? (
            renderSearchResult(selectedCompany, 0)
          ) : (
            <>{companies.map((data, i) => renderSearchResult(data, i))}</>
          )}
        </div>
      </div>
    </SearchResultStyles>
  );
};
export default SearchResult;
