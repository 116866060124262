import React, { useState, useEffect, useRef, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { ShimmerThumbnail } from "react-shimmer-effects";

import CompanyDetailStyles from "./CompanyDetailStyles";
import SearchResult from "../../../../components/Jobs/Visa/SearchResult/SearchResult";
import CompanyInfo from "../../../../components/Jobs/Visa/CompanyInfo/CompanyInfo";
import {
  updateFavouriteCompanyAPI,
  getSavedCompaniesAPI,
  getJobsCompanyDetailsAPI,
  searchJobsAPI,
  searchTopEmployerJobsAPI,
} from "../../../../services/JobServices";
import usePromise from "../../../../hooks/usePromise/usePromise";
import {
  toastify,
  cancel_token,
  processJobLocationTime,
  getInitialVisaFilters,
} from "../../../../helper/helper";
import SelectiveSavedCompanies from "../../../../components/Jobs/Visa/SelectiveSavedCompanies/SelectiveSavedCompanies";
import { setSelectedCompany } from "../../../../redux/reducers/jobReducer";
import { routes } from "../../../../routes";
import SearchFilter from "../../../../components/Jobs/SearchFilter/SearchFilter";
import { useAppState } from "../../../../context";

import BackArrow from "../../../../assets/svg/f-btn-arrow-gray.svg";

const INITIAL_POSITION_FILTERS = {
  search: "",
  country: "us",
  state: null,
  city: "",
  publish_date: null,
  degree: null,
  experience: [],
  job_type: null,
  work_type: null,
  job_search_type: "approx",
};

const CompanyDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { permissions = {} } = useSelector((store) => store.userStore);
  const { selectedCompany = null, appliedVisaFilters = {} } = useSelector(
    (store) => store.jobStore
  );

  let query = new URLSearchParams(useLocation().search);
  let saved = query.get("saved");
  let company_id = query.get("company_id");
  let employer_name = query.get("employer_name");

  const redirectedFromJobs = company_id || employer_name;

  const showPetition =
    appliedVisaFilters.visa &&
    (appliedVisaFilters.visa === "h1b" ||
      appliedVisaFilters.visa === "greencard");

  const { jobs = [], setJobs } = useAppState("job");

  const [activeTab, setActiveTab] = useState(
    showPetition || redirectedFromJobs ? "petition" : "position"
  );
  const [positionFilters, setPositionFilters] = useState({
    ...INITIAL_POSITION_FILTERS,
  });
  const [savedJobsFilter, setSavedJobsFilter] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [jobsLoader, setJobsLoader] = useState(false);
  const [sortByPosition, setSortByPosition] = useState("date");
  const [savedCompaniesList, setSavedCompaniesList] = useState([]);
  const [callSavedCompaniesAPI, refreshSavedCompaniesAPI] =
    usePromise(getSavedCompaniesAPI);
  const [callingUpdateFavouriteCompanyAPI, refreshUpdateFavouriteCompanyAPI] =
    usePromise(updateFavouriteCompanyAPI);
  const [callGetJobsCompanyDetailsAPI, refreshGetJobsCompanyDetailsAPI] =
    usePromise(getJobsCompanyDetailsAPI);
  const [showLoader, setShowLoader] = useState(redirectedFromJobs);
  const [jobCategoriesCount, setJobCategoriesCount] = useState([]);
  const [searchTopEmployerJobsAPILoader, setSearchTopEmployerJobsAPILoader] =
    useState(false);
  const [searchJobsAPILoader, setSearchJobsAPILoader] = useState(false);

  const searchAPIRef = useRef(null);

  const INITIAL_VISA_FILTERS = useMemo(() => {
    return getInitialVisaFilters(permissions);
  }, [permissions]);

  // No need to apply any saved visa filters if redirected from jobs
  const visaFilters = redirectedFromJobs
    ? INITIAL_VISA_FILTERS
    : appliedVisaFilters;

  const { visa = "h1b" } = visaFilters;

  const {
    search,
    country = "us",
    state,
    city,
    job_type,
    work_type,
    publish_date,
    degree,
    experience = [],
    job_search_type,
  } = positionFilters;

  const searchType = country && country === "us" ? "us" : "international";

  useEffect(() => {
    if (redirectedFromJobs)
      refreshGetJobsCompanyDetailsAPI(company_id, employer_name);
    refreshSavedCompaniesAPI(visa);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      callGetJobsCompanyDetailsAPI.hasFetched() &&
      callGetJobsCompanyDetailsAPI.hasErrors() === false
    ) {
      if (
        callGetJobsCompanyDetailsAPI.data() &&
        callGetJobsCompanyDetailsAPI.data().data
      ) {
        const companyDetails = callGetJobsCompanyDetailsAPI.data().data;
        dispatch(
          setSelectedCompany({
            company_id,
            company_name: employer_name,
            tab_type: companyDetails?.company_type,
            ...companyDetails,
          })
        );
        setShowLoader(false);
      } else {
        navigate(`${routes.JOBS.VISA}?type=company`);
      }
    } else if (
      callGetJobsCompanyDetailsAPI.hasFetched() &&
      callGetJobsCompanyDetailsAPI.hasErrors() === true
    ) {
      navigate(`${routes.JOBS.VISA}?type=company`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callGetJobsCompanyDetailsAPI.isFetching()]);

  useEffect(() => {
    if (
      callingUpdateFavouriteCompanyAPI.hasFetched() &&
      callingUpdateFavouriteCompanyAPI.hasErrors() === false
    ) {
      refreshSavedCompaniesAPI(visa);
      toastify("success", callingUpdateFavouriteCompanyAPI.data().message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingUpdateFavouriteCompanyAPI.isFetching()]);

  useEffect(() => {
    if (
      callSavedCompaniesAPI.hasFetched() &&
      callSavedCompaniesAPI.hasErrors() === false
    ) {
      if (callSavedCompaniesAPI.data() && callSavedCompaniesAPI.data().data) {
        setSavedCompaniesList(
          callSavedCompaniesAPI.data().data.favorite_companies
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callSavedCompaniesAPI.isFetching()]);

  const updateFavourites = ({ company_id, tab_type, employer_name, like }) => {
    refreshUpdateFavouriteCompanyAPI({
      tab_type:
        tab_type && like === "unlike" ? tab_type : appliedVisaFilters.visa,
      company_id,
      company_name: employer_name,
      like,
    });
  };

  useEffect(() => {
    if (activeTab === "petition")
      setPositionFilters({
        country: "us",
      });
    else if (activeTab === "position") {
      setCurrentPage(1);
      setJobsLoader(true);
      applyFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  const callSearchTopEmployerJobsAPI = async (
    params = {},
    freshData = false
  ) => {
    setSearchTopEmployerJobsAPILoader(true);
    if (searchAPIRef?.current?.cancel) {
      searchAPIRef.current.cancel("Operation cancelled by user");
    }
    const cancelToken = cancel_token();
    searchAPIRef.current = cancelToken;
    const response = await searchTopEmployerJobsAPI({
      // Do not pass visa attribute in params for companny positions
      params: { ...params, visa: "companny_positions" },
      cancelToken: cancelToken.token,
    });
    if (response?.success && response?.data) {
      setTotalPages(response.data.total_pages);
      const formattedJobs = response.data.jobs.map((rec) => {
        const formattedRec = {
          ...rec,
          ...rec.api_response,
        };
        delete formattedRec.api_response;
        // Need job location to compare group jobs based on location as well
        formattedRec.interstride_location = processJobLocationTime({
          country: formattedRec?.country,
          state: formattedRec?.state,
          city: formattedRec?.city,
          time: null,
        });
        return formattedRec;
      });
      setJobs([...(freshData ? [] : jobs), ...formattedJobs]);
      setJobCategoriesCount(response.data.job_categories);
      setJobsLoader(false);
    }
    searchAPIRef.current = null;
    setSearchTopEmployerJobsAPILoader(false);
  };

  const callSearchJobsAPI = async (params = {}, freshData = false) => {
    setSearchJobsAPILoader(true);
    if (searchAPIRef?.current?.cancel) {
      searchAPIRef.current.cancel("Operation cancelled by user");
    }
    const cancelToken = cancel_token();
    searchAPIRef.current = cancelToken;
    const response = await searchJobsAPI({
      params,
      cancelToken: cancelToken.token,
    });
    if (response?.success && response?.data) {
      setTotalPages(response.data.total_pages);
      const formattedJobs = response?.data?.jobs.map((rec) => {
        const formattedRec = {
          ...rec,
        };
        // Need job location to compare group jobs based on location as well
        formattedRec.interstride_location = processJobLocationTime({
          country: formattedRec?.country,
          state: formattedRec?.state,
          city: formattedRec?.city,
          time: null,
        });
        return formattedRec;
      });
      setJobs([...(freshData ? [] : jobs), ...formattedJobs]);
      setJobCategoriesCount(response.data.job_categories);
      setJobsLoader(false);
    }
    searchAPIRef.current = null;
    setSearchJobsAPILoader(false);
  };

  const applyFilters = (params = {}) => {
    setJobs([]);
    setJobsLoader(true);

    let defaultParams = {
      job_region: searchType,
      search: search === null ? "" : search,
      country,
      city,
      job_type: (job_type || [])?.join(),
      work_type: (work_type || [])?.join(),
      publish_date,
      degree: (degree || [])?.join(),
      experience: (experience || [])?.join(),
      job_search_type,
      state,
      page: 1,
      company_name: selectedCompany?.company_name,
      company_id: selectedCompany?.company_id,
      ...params,
    };

    setCurrentPage(1);
    if (
      defaultParams.country === "us" &&
      (visa === "h1b" || visa === "top_500_employers" || visa === "cap_exempt")
    )
      callSearchTopEmployerJobsAPI(
        {
          ...defaultParams,
          sort: sortByPosition,
        },
        true
      );
    else
      callSearchJobsAPI(
        {
          ...defaultParams,
          sort: sortByPosition,
        },
        true
      );

    setSavedJobsFilter(defaultParams);
  };

  const getPaginationData = () => {
    const defaultParams = {
      ...savedJobsFilter,
      company_name: selectedCompany?.company_name,
      company_id: selectedCompany?.company_id,
      page: currentPage + 1,
    };

    setCurrentPage(currentPage + 1);

    if (
      defaultParams.country === "us" &&
      (visa === "h1b" || visa === "top_500_employers" || visa === "cap_exempt")
    )
      callSearchTopEmployerJobsAPI({
        ...defaultParams,
        sort: sortByPosition,
      });
    else
      callSearchJobsAPI({
        ...defaultParams,
        sort: sortByPosition,
      });
  };

  const updatePositionSortBy = (newSortBy) => {
    setJobs([]);
    setJobsLoader(true);
    const defaultParams = {
      ...savedJobsFilter,
      company_name: selectedCompany?.company_name,
      company_id: selectedCompany?.company_id,
      page: 1,
    };

    setCurrentPage(1);
    setSortByPosition(newSortBy);

    if (
      defaultParams.country === "us" &&
      (visa === "h1b" || visa === "top_500_employers" || visa === "cap_exempt")
    )
      callSearchTopEmployerJobsAPI(
        {
          ...defaultParams,
          sort: newSortBy,
        },
        true
      );
    else
      callSearchJobsAPI(
        {
          ...defaultParams,
          sort: newSortBy,
        },
        true
      );
  };

  const clearAll = () => {
    setJobsLoader(true);
    setPositionFilters({
      ...positionFilters,
      ...INITIAL_POSITION_FILTERS,
    });
    applyFilters({
      ...positionFilters,
      ...INITIAL_POSITION_FILTERS,
    });
  };

  return (
    <CompanyDetailStyles>
      <div className="company-mainpage">
        {showLoader ? (
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <ShimmerThumbnail height={500} />
            </Grid>
            <Grid item xs={6}>
              <ShimmerThumbnail height={500} />
            </Grid>
            <Grid item xs={3}>
              <ShimmerThumbnail height={500} />
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={3}>
              {activeTab === "petition" ? (
                <>
                  {saved ? (
                    <SelectiveSavedCompanies
                      back2={"searchList"}
                      savedCompaniesList={savedCompaniesList}
                      updateFavourites={updateFavourites}
                    />
                  ) : (
                    <SearchResult
                      back2={redirectedFromJobs ? "jobs" : "searchList"}
                      savedCompaniesList={savedCompaniesList}
                      updateFavourites={updateFavourites}
                      fixedHeight
                    />
                  )}
                </>
              ) : (
                <>
                  <div className="back-btn-wrapper">
                    <a
                      className="shadow-btn"
                      onClick={(e) => {
                        e.preventDefault();
                        navigate(routes.JOBS.VISA, {
                          state: { preserveFilters: true },
                        });
                      }}
                      href="/#"
                      aria-label="Back"
                    >
                      <img
                        src={BackArrow}
                        id="BackArrow"
                        width="18px"
                        alt="Back arrow"
                      />
                      <span>Back to Search</span>
                    </a>
                  </div>
                  <SearchFilter
                    type="visa_insights"
                    filter={positionFilters}
                    setFilter={(changedFilters) => {
                      setPositionFilters({
                        ...positionFilters,
                        ...changedFilters,
                      });
                    }}
                    onClearAllClick={clearAll}
                    applyFilters={applyFilters}
                    jobCategoriesCount={jobCategoriesCount}
                  />
                </>
              )}
            </Grid>
            <Grid item xs={9}>
              <CompanyInfo
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                filters={positionFilters}
                savedCompaniesList={savedCompaniesList}
                updateFavourites={updateFavourites}
                showPetition={showPetition}
                jobsLoader={jobsLoader}
                visaFilters={visaFilters}
                totalPages={totalPages}
                currentPage={currentPage}
                sortByPosition={sortByPosition}
                getPaginationData={getPaginationData}
                updatePositionSortBy={updatePositionSortBy}
                showPositionPaginationLoader={
                  searchJobsAPILoader || searchTopEmployerJobsAPILoader
                }
              />
            </Grid>
          </Grid>
        )}
      </div>
      {/* </MainLayout> */}
    </CompanyDetailStyles>
  );
};
export default CompanyDetail;
