import styled from "styled-components";
import { PRIMARY_COLOR } from "../../../../styles/variables";

export default styled.div.attrs({ className: "" })`
  .search-list {
    display: block;
    .title-primary {
      display: flex;
      align-items: center;
      font-family: "TTCommons-DemiBold";
    }

    .search-card {
      overflow: visible;
      &:hover {
        box-shadow: 0 1px 14px 3px var(--gray-outline-color);
      }
      .card-content {
        padding: 16px 20px 14px;
      }
      &.active {
        .avtar {
          .MuiAvatar-root {
            &.white-background {
              background: #fff;
            }
          }
        }
        background: ${PRIMARY_COLOR};
        .header-primary__title,
        .sub-header-primary {
          color: #ffffff !important;
        }
        .text-grey {
          color: var(--medium-gray-color);
        }
      }
      .search-title {
        display: flex;
        justify-content: space-between;
      }
      .search-item {
        display: flex;
        flex-wrap: no-wrap;
        align-items: center;
        .MuiAvatar-root {
          width: 50px;
          height: 50px;
          padding: 0px;
          margin: 0 10px 0 0;
          border-radius: 100px;
          &.initial-avatar {
            padding-top: 8px;
          }
          img {
            height: auto;
            object-fit: initial;
          }
        }
        .content {
          width: calc(100% - 60px);
          .header-primary {
            display: flex;
            gap: 8px;
            margin-bottom: 3px;
            &__title {
              color: var(--primary-dark-color);
              font-family: "TTCommons-Medium";
              font-size: 18px;
              font-weight: 500;
              letter-spacing: 0;
              line-height: 18px;
              word-break: break-word;
              margin: 0 0 0 0 !important;
            }
            &__star {
              width: 18px;
              height: 18px;
              margin-top: -2px;
              img {
                width: 18px;
                height: 18px;
              }
            }
          }
          .sub-header-primary {
            font-size: 15px;
            letter-spacing: 0;
            line-height: 17px;
            color: var(--primary-dark-color);
            margin-bottom: 2px;
          }
        }
      }
    }
  }
`;
