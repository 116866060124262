import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Avatar } from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import { isEqual } from "lodash";
import { useSelector, useDispatch } from "react-redux";

import FilterComapanyStyles from "./FilterComapanyStyles";
import mapMode from "../../../../assets/svg/f-Maps.svg";
import { numberWithCommas } from "../../../../helper/helper";
import CardCustom from "../../../CardCustom/CardCustom";
import ButtonCustom from "../../../ButtonCustom/ButtonCustom";
import StarIcon from "../../../StarIcon/StarIcon";
import Loader from "../../../../components/Loader/Loader";
import EmptyListWithImage from "../../../../components/EmptyListWithImage/EmptyListWithImage";
import InitialAvatar from "../../../../components/InitialAvatar/InitialAvatar";
import ExportDropdown from "../../../../components/ExportDropdown/ExportDropdown";
import JobsListingEffect from "../../../effects/JobsListingEffect/JobsListingEffect";
import { setSelectedCompany } from "../../../../redux/reducers/jobReducer";

const FilterComapany = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { appliedVisaFilters = {}, companies = [] } = useSelector(
    (store) => store.jobStore
  );

  const accessibilityHelp = localStorage.getItem("accessibilityHelp");
  const {
    fetchMoreData,
    hasMore,
    showPaginationLoader = false,
    initialFilters = {},
    filter = {},
    onCompaniesExport = () => {},
    saveCompaniesFeature = false,
  } = props;

  const [viewMode, setViewMode] = useState(true);

  const chageViewMode = () => {
    if (viewMode) {
      dispatch(setSelectedCompany(null));
    }
    setViewMode(!viewMode);
    props.sendMode(!viewMode);
  };

  const openCompany = (data) => {
    dispatch(setSelectedCompany(data));
    navigate(`/jobs/visa-insights/company`);
  };

  const favCompanyKeyList = saveCompaniesFeature
    ? props.savedCompaniesList && props.savedCompaniesList.length > 0
      ? props.savedCompaniesList.map(({ company_id }) => company_id)
      : []
    : [];

  const renderFilterComapany = (data, i) => {
    return (
      <CardCustom className="mb-15">
        <div className="company-item">
          <div className="avtar">
            {data.company_logo ? (
              <Avatar
                variant="rounded"
                alt={
                  data.employer_name
                    ? `${data.employer_name}'s logo`
                    : "default logo"
                }
                src={data.company_logo}
              />
            ) : (
              <InitialAvatar
                variant="rounded"
                title={data.employer_name}
                index={i}
                initialCount={1}
              />
            )}
          </div>
          <div className="content">
            <div className="header-primary">{data.employer_name}</div>
            {data.wage_rate_of_pay_from && (
              <div className="sub-header-primary">
                Average Salary: ${numberWithCommas(data.wage_rate_of_pay_from)}
              </div>
            )}
          </div>
          <div
            className={`card-actions-wrapper ${
              accessibilityHelp == "true" ? "show-actions" : ""
            }`}
          >
            <div className="pentition">
              {numberWithCommas(data.no_of_petition)} petitions
            </div>
            <div className="view">
              <ButtonCustom
                width="100"
                height="30"
                onClick={() => openCompany({ ...data, visa: filter.visa })}
              >
                View
              </ButtonCustom>
            </div>
            {saveCompaniesFeature &&
            data.company_id &&
            !favCompanyKeyList.includes(data.company_id) ? (
              <StarIcon
                aria-label={`Add ${data.employer_name} to saved companies`}
                onClick={() =>
                  props.updateFavourites({
                    ...data,
                    like: "like",
                  })
                }
              />
            ) : null}
          </div>
          {saveCompaniesFeature &&
          data.company_id &&
          favCompanyKeyList.includes(data.company_id) ? (
            <StarIcon
              aria-label={`Remove ${data.employer_name} from saved companies`}
              variant="filled"
              onClick={(e) =>
                props.updateFavourites({
                  ...data,
                  like: "unlike",
                })
              }
              className="ml-16"
            />
          ) : null}
        </div>
      </CardCustom>
    );
  };
  return (
    <FilterComapanyStyles>
      <div className="company-list">
        {props.loader ? (
          <JobsListingEffect count={10} />
        ) : (
          <React.Fragment>
            <div className="company-title mb-15">
              {companies.length > 0 && (
                <h2 className="title-primary">Companies</h2>
              )}
              {companies.length > 0 &&
                appliedVisaFilters.visa &&
                (appliedVisaFilters.visa === "h1b" ||
                  appliedVisaFilters.visa === "greencard") && (
                  <div className="mode">
                    <ButtonCustom
                      width="100"
                      height="30"
                      color="primary"
                      onClick={chageViewMode}
                    >
                      <img src={mapMode} alt="map mode" />
                    </ButtonCustom>
                  </div>
                )}
              {/* Show export option only if any filter is applied */}
              {!isEqual(initialFilters, appliedVisaFilters) && (
                <div className="export-dropdown-container">
                  <ExportDropdown
                    options={[
                      {
                        id: "pdf",
                        title: "PDF",
                      },
                      { id: "csv", title: "Excel" },
                    ]}
                    onExport={onCompaniesExport}
                  />
                </div>
              )}
            </div>
            <Grid container spacing={3}>
              {companies.length > 0 ? (
                <>
                  {accessibilityHelp == "true" ? (
                    <div>
                      <Grid container spacing={3}>
                        {companies.map((data, i) =>
                          renderFilterComapany(data, i)
                        )}
                      </Grid>

                      {hasMore && (
                        <center>
                          <div style={{ margin: "20px 0 0 0" }}>
                            {showPaginationLoader ? (
                              <Loader />
                            ) : (
                              <ButtonCustom onClick={fetchMoreData}>
                                Load More
                              </ButtonCustom>
                            )}
                          </div>
                        </center>
                      )}
                    </div>
                  ) : (
                    <InfiniteScroll
                      dataLength={companies.length}
                      next={fetchMoreData}
                      hasMore={hasMore}
                      loader={
                        <div style={{ margin: "20px 0 0 0" }}>
                          <JobsListingEffect count={3} />
                        </div>
                      }
                      style={{ overflow: "none" }}
                    >
                      {companies.map((data, i) =>
                        renderFilterComapany(data, i)
                      )}
                    </InfiniteScroll>
                  )}
                </>
              ) : (
                <div className="n-empty-list">
                  <EmptyListWithImage
                    title={`Looks like there are no companies with this search.`}
                    subTitle={`Please select fewer filters.`}
                    className={"default-empty-cover"}
                  />
                </div>
              )}
            </Grid>
          </React.Fragment>
        )}
      </div>
    </FilterComapanyStyles>
  );
};
export default FilterComapany;
